import Http from '@/utils/http'
const baseUrl = '/app/study'


/**
 * 获取短信验证码
 * @param mobile[string]
 * 
*/
export const getCode = mobile => Http.post(`${baseUrl}/sms`, mobile)

/**
 * 短信登入
 * @param params
 * 
*/

export const login = params => Http.post(`${baseUrl}/login`, params)

/**
 * 账密登入
 * @param params
 * 
*/

export const accountLogin = params => Http.post(`${baseUrl}/account/login`, params)

/**
 * 注册
 * @param params
 * 
*/

export const register = params => Http.post(`${baseUrl}/register`, params)